import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import * as LegacyIcons from "../src/index-legacy";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconName = makeShortcode("IconName");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons-legacy"
    }}>{`Icons legacy`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { IconCommute, IconDrive } from "../src";
<IconCommute width="56px" height="56px" />
<IconDrive width="56px" height="56px" />
`}</code></pre>
    <h2 {...{
      "id": "list-of-icons"
    }}>{`List of icons`}</h2>
    <table>
  <tbody>
    <tr><td>Name</td><td>Example</td></tr>
    {Object.keys(LegacyIcons).map(i => {
          const IconName = LegacyIcons[i];
          return <tr key={i}>
        <td>{i}</td>
        <td>{<IconName height="36px" width="36px" mdxType="IconName" />}</td>
      </tr>;
        })}
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      